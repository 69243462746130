import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de fins a 15 cm de diàmetre, lluent en temps sec i viscós en temps humit. És de color marró castany més o menys clar a rogenc. Les làmines són nombroses, atapeïdes, fràgils i de color blanc amb taques rogenques. El peu és robust amb una zona anular que delimita per damunt el color blanc i per davall del mateix color que el capell. Les espores són blanques en massa, el·lipsoidals, de 5,5 x 4,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      